<template>
  <div>
    <rxNavBar :title="title"></rxNavBar>
    <div>
      <div class="part">
        <div class="part-inputpart">
          <div class="part-inputpart-row">
            <div
              :class="0 == name.trim().length ? 'content-none' : 'content-have'"
            ></div>
            <span class="part-inputpart-row-header">姓名</span>
            <span class="content-divide">|</span>
            <!-- <input v-model="name" placeholder="请输入姓名"/> -->
            <span>{{ name }}</span>
          </div>
          <!-- <div class="part-inputpart-row">
                        <div :class=" 0==sex.trim().length ? 'content-none' : 'content-have'"></div>
                        <span class="part-inputpart-row-header">性别</span>
                        <div>
                            <span class="part-inputpart-man" :class="'1'==sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' " @click="mansex">男</span>
                            <span class="content-divide">|</span>
                            <span class="part-inputpart-woman" :class="'0'==sex ? 'part-inputpart-row-redtext' : 'part-inputpart-row-graytext' " @click="womansex">女</span>
                        </div>
                    </div> -->
          <div class="part-inputpart-row">
            <span
              :class="
                0 == linkWay.trim().length ? 'content-none' : 'content-have'
              "
            ></span>
            <span class="part-inputpart-row-header">联系方式</span>
            <span class="content-divide">|</span>
            <!-- <input
              type="tel"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              v-model="linkWay"
              placeholder="填写联系方式"
              @blur="checkPhone"
            /> -->
            <span>{{ linkWay }}</span>
          </div>
          <div id="recommendTypePanel" class="part-inputpart-row">
            <!--@click="isRecommendTypeShow = !isRecommendTypeShow"-->
            <div
              :class="
                !recommendType.recommendTypeName
                  ? 'content-none'
                  : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">途径</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                !recommendType.recommendTypeName
                  ? ''
                  : 'part-inputpart-row-normaltext'
              "
              >{{ recommendType.recommendTypeName }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                isRecommendTypeShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
          <div
            id="papersTypePanel"
            class="part-inputpart-row"
            @click="papersTypeShow = !papersTypeShow"
          >
            <div
              :class="
                !papersType.dictionaryTitle ? 'content-none' : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">证件类型</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                !papersType.dictionaryTitle
                  ? ''
                  : 'part-inputpart-row-normaltext'
              "
              >{{ papersType.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                papersTypeShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
          <div class="part-inputpart-row">
            <div
              :class="
                0 == number.trim().length ? 'content-none' : 'content-have'
              "
            ></div>
            <span class="part-inputpart-row-header">证件号</span>
            <span class="content-divide">|</span>
            <input v-model="number" placeholder="请输入证件号" />
          </div>
          <div class="part-inputpart-row" @click="chooseDepartment">
            <div
              :class="
                department.newDepartment_id ? 'content-have' : 'content-none'
              "
            ></div>
            <span class="part-inputpart-row-header">试岗部门</span>
            <span class="content-divide">|</span>
            <input
              v-model="department.newDepartment_name"
              placeholder="试岗部门"
            />
          </div>
          <div class="part-inputpart-row" @click="newDutyShow = true">
            <span
              :class="newDuty.newDuty_id ? 'content-have' : 'content-none'"
            ></span>
            <span class="part-inputpart-row-header">职务</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                !newDuty.newDuty_id ? '' : 'part-inputpart-row-normaltext'
              "
              >{{
                newDuty.newDuty_name ? newDuty.newDuty_name : "请选择"
              }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                newDutyShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>

          <div
            class="part-inputpart-row"
            @click="educationShow = !educationShow"
          >
            <div class="content-no"></div>
            <span class="part-inputpart-row-header">学历</span>
            <span class="content-divide">|</span>
            <span
              class="part-inputpart-row-graytext"
              :class="
                !education.dictionaryTitle
                  ? ''
                  : 'part-inputpart-row-normaltext'
              "
              >{{ education.dictionaryTitle }}</span
            >
            <img
              class="part-inputpart-row-right"
              :class="
                educationShow
                  ? 'part-inputpart-row-right-upArrow'
                  : 'part-inputpart-row-right-downArrow'
              "
              src="../../../assets/images/triangle.png"
            />
          </div>
        </div>
      </div>
      <div
        @click="saveData"
        class="part part-button"
        :class="
          0 == name.trim().length ||
          0 == linkWay.trim().length ||
          0 == number.trim().length ||
          !recommendType.recommendTypeName ||
          !papersType.dictionaryTitle ||
          !department.newDepartment_name ||
          !newDuty.newDuty_name
            ? ''
            : 'part-button-enabled'
        "
      >
        确定
      </div>
    </div>
    <van-popup v-model="isRecommendTypeShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="recommendTypeArr"
        @cancel="isRecommendTypeShow = false"
        :default-index="recommendTypeIndex"
        value-key="recommendTypeName"
        @confirm="selectRecommendTypeOption"
      />
    </van-popup>
    <!-- 证件类型 -->
    <van-popup v-model="papersTypeShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="papersTypeArr"
        @cancel="papersTypeShow = false"
        :default-index="papersTypeIndex"
        value-key="dictionaryTitle"
        @confirm="selectPapersTypeOption"
      />
    </van-popup>
    <!-- 学历 -->
    <van-popup v-model="educationShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="educationArr"
        @cancel="educationShow = false"
        :default-index="educationIndex"
        value-key="dictionaryTitle"
        @confirm="selectEducationeOption"
      />
    </van-popup>
    <!-- 职务下拉 -->
    <van-popup v-model="newDutyShow" position="bottom">
      <div class="searchDiv">
        <van-search
          placeholder="请输入"
          v-model="searchstaffNames"
          size="large"
          @input="initDutyData()"
        />
      </div>
      <van-picker
        show-toolbar
        value-key="dutyName"
        :columns="dutyList"
        @cancel="newDutyShow = false"
        @confirm="newDutyEvent"
      />
    </van-popup>

    <!-- 推荐人弹框 -->
    <van-popup
      v-model="recomShows"
      class="van-popup--bottom"
      position="bottom"
      :style="{ height: 'auto' }"
    >
      <img class="cancelOrderPopupImg" src="../../../assets/images/colon.png" />
      <div class="cancelOrderPopupTitle">确认</div>
      <div class="cancelOrderPopupText">{{ recomTitle }}</div>
      <div style="display: flex; text-align: center">
        <div style="width: 50%">
          <van-button @click="comfirmss" class="saveButton_able"
            >确认</van-button
          >
        </div>
        <div style="width: 50%">
          <van-button @click="close" class="saveButton_Enable">取消</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { NavBar, Picker, Popup, Toast, Button, Search } from "vant";
import {
  RecommendResumeInit,
  startInterPolateApproval,
  checkResume,
  queryBaseData,
} from "../../../getData/getData";
import {
  globaluserId,
  checkAndroid,
  checkIOS,
  responseUtil,
  getStaffId,
} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import eventBus from "../../../components/rongxun/rx-navBar/envbus.js";
import { queyDutySelList, newQueyDutySelList } from "../../../getData/getData";

//调用android关闭页面方法*******begin*********
function backToAndroid() {
  window.himi.closeWindow();
}
//调用android关闭页面方法*******end***********
export default {
  name: "RecommendResume",

  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Search.name]: Search,
    rxNavBar,
  },

  data() {
    return {
      recomShows: false,
      recommendTypeIndex: "",
      papersTypeIndex: 0,
      educationIndex: 0,
      //途径
      isRecommendTypeShow: false,
      papersTypeShow: false,
      educationShow: false,
      isRecommenderSelected: false,
      papersTypeSelected: false,
      educationSelected: false,
      name: "",
      number: "",
      sex: "",
      linkWay: "",
      recommendType: {},
      papersType: "",
      education: "",
      department: {
        newDepartment_name: "",
        newDepartment_id: "",
      },
      newDuty: {
        newDuty_name: "",
        newDuty_id: "",
      },
      recommendTypeArr: [],
      papersTypeArr: [],
      educationArr: [],
      title: "试岗",
      newDutyShow: false,
      dutyList: [],
      recomTitle: "已有推荐人，是否继续？",
      allDate: {},
      uId: "",
      searchstaffNames: "",
    };
  },

  mounted: function () {
    // 初始化
    this.initData();
    this.getDict();
    this.initDutyData();
  },
  activated() {
    let that = this;
    let routeData = this.$route.query;
    this.setDate(routeData);

    //动态修改this.$route.query值
    if (routeData.flag == "trialJob") {
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      newQuery.flag = "";
      this.$router.replace({
        query: newQuery,
      });
      this.clear();
    }

    eventBus.$on(
      "chooseDepartment",
      function (data) {
        console.log(data, "data====");
        if (data) {
          that.department.newDepartment_id = data.selectId;
          that.department.newDepartment_name = data.selectName;
        }
      }.bind(this)
    );
  },

  methods: {
    comfirmss() {
      this.commit(this.allDate);
      this.clear();
      this.recomShows = false;
    },
    close() {
      this.recomShows = false;
    },
    getDict() {
      let that = this;
      const initData = {
        dbName: ["education"],
        fdName: ["CERTIFICATETYPEMAP"],
      };
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          let data = response.data.data;
          //证件类型
          that.papersTypeArr = data.CERTIFICATETYPEMAP;
          //学历
          that.educationArr = data.education;
        });
      });
    },
    chooseDepartment() {
      this.$router.push({
        name: "departmentSelect",
        query: {
          selectId: this.department.newDepartment_id || "",
          selectName: this.department.newDepartment_name || "",
        },
      });
    },
    initDutyData: function () {
      var that = this;
      let initData = {};
      initData.user_id = getStaffId();
      initData.dutyName = that.searchstaffNames;
      newQueyDutySelList(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.dutyList = response.data.data.dutyList;
        });
      });
    },
    // 职务
    newDutyEvent(value) {
      this.newDuty.newDuty_id = value.id;
      this.newDuty.newDuty_name = value.dutyName;
      this.newDutyShow = false;
    },

    checkPhone() {
      var reg =
          /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if ("" != this.linkWay) {
        if (!reg.test(this.linkWay)) {
          Toast("手机号格式错误");
          this.linkWay = "";
        }
      }
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1);
      //APP传值*******begin*********
      if (checkAndroid()) {
        backToAndroid();
      } else if (checkIOS()) {
        console.log("对ios传参");
        window.webkit.messageHandlers.closeWindow.postMessage({});
      }
      //APP传值*******end*********
    },
    //点击下拉框区域外 下拉框隐藏
    clickRecommendTypeShow() {
      this.isRecommendTypeShow = !this.isRecommendTypeShow;
      var panel = document.getElementById("recommendTypePanel");
      if (panel) {
        document.addEventListener("click", (e) => {
          if (!panel.contains(e.target)) {
            this.isRecommendTypeShow = false;
          }
        });
      }
    },
    //点击下拉框选项 进行数据绑定
    selectRecommendTypeOption(item, index) {
      if (item) {
        this.recommendType = item;
        this.recommendTypeIndex = index;
      }
      this.isRecommendTypeShow = false;
      this.isRecommenderSelected = true;
    },
    selectPapersTypeOption(item, index) {
      console.log(item);
      if (item) {
        this.papersType = item;
        this.papersTypeIndex = index;
      }
      this.papersTypeShow = false;
      this.papersTypeSelected = true;
    },
    selectEducationeOption(item, index) {
      console.log(item);
      if (item) {
        this.education = item;
        this.educationIndex = index;
      }
      this.educationShow = false;
      this.educationSelected = true;
    },

    mansex() {
      this.sex = "1";
    },

    womansex() {
      this.sex = "0";
    },

    // 页面初始化
    initData: function () {
      var that = this;
      let initData = {};
      initData.user_id = globaluserId;
      RecommendResumeInit(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.recommendTypeArr = response.data.data.recommendTypeList;
        });
      });
    },

    // 保存数据
    saveData: function () {
      var that = this;
      let initData = {};
      if (0 == that.name.trim().length) return;
      if (0 == that.number.trim().length) return;
      if (0 == that.linkWay.trim().length) return;
      // if(0==that.sex.trim().length) return
      // if (!that.isRecommenderSelected) return;
      // if (!that.papersTypeSelected) return;
      if (!that.department.newDepartment_name) return;
      if (!that.newDuty.newDuty_name) return;
      initData.staff_id = getStaffId();
      initData.id = that.uId;
      initData.name = that.name.replace(/(^\s*)|(\s*$)/g,"");
      initData.idCard = that.number;
      // initData.sex = that.sex
      initData.recommendType = that.recommendType.recommendType_id;
      initData.staffCertificateType = that.papersType.dictionaryValue;
      initData.linkWay = that.linkWay;
      initData.department_id = that.department.newDepartment_id;
      initData.duty_id = that.newDuty.newDuty_id;
      initData.education_id = that.education.dictionaryValue || "";
      that.selectResume(that.number, initData);
    },
    // 查询是否已有推荐人
    selectResume(number, date) {
      let that = this;
      let initData = {};
      initData.idCard = number;
      (initData.staff_id = getStaffId()),
        checkResume(initData).then((res) => {
          console.log(res.data.data.hadMaster);
          let ishave = res.data.data.hadMaster;
          if (ishave == "Y") {
            that.allDate = date;
            that.recomTitle = "已有师傅，是否继续？";
            that.recomShows = true;
          } else if (ishave == "N") {
            that.commit(date);
          } else if (ishave == "D") {
            that.allDate = date;
            that.recomTitle = "已有师傅(且互为师傅)，是否继续？";
            that.recomShows = true;
          }
        });
    },
    //提交
    commit(data) {
      let that = this;
      startInterPolateApproval(data).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertMsg(that, "试岗成功");
          that.clear();
          that.$router.go(-1)
          // that.leftReturn();
        });
      });
    },
    //清除数据
    clear() {
      let that = this;
      // that.user_id = "";
      // that.uId = "";
      // that.name = "";
      // that.sex = "";
      // that.recommendType = {};
      // that.linkWay = "";
      that.searchstaffNames = "";
      that.number = "";
      that.education = {};
      that.papersType = {};
      that.department = {
        newDepartment_name: "",
        newDepartment_id: "",
      };
      that.newDuty = {
        newDuty_name: "",
        newDuty_id: "",
      };
    },
    //修改赋值
    setDate(val) {
      let that = this;
      that.user_id = val.user_id;
      that.uId = val.id;
      that.name = val.name.replace(/(^\s*)|(\s*$)/g,"");
      // that.sex = val.gender;
      that.recommendType.recommendType_id = val.resumeWay_id;
      that.recommendType.recommendTypeName = val.resumeWayStr;
      that.linkWay = val.phoneNum;
    },
  },
};
</script>

<style scoped>
.title-part {
  position: relative;
  margin-top: 24px;
}

.title-part img {
  width: 68px;
}

.title-part span {
  position: absolute;
  z-index: 10;
  font-weight: 900;
  font-size: 27px;
  top: 39px;
  left: 33px;
}

.part {
  margin: 15px;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-button {
  height: 45px;
  border-radius: 10px;
  background-color: #ededed;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 900;
  margin-top: 100px;
}

.part-button-enabled {
  background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}

.part-inputpart div:last-child {
  border: none;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  height: 45px;
  background-color: #ffffff;
  margin-top: 15px;
}

.part-inputpart-row-header {
  font-weight: 900;
}

.part-inputpart-row input {
  border: 0;
  /*width: 100%;*/
  flex: auto;
}

.part-inputpart-row input::-webkit-input-placeholder {
  color: #d8d8d8;
}

.part-inputpart-dropDownList {
  height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 92.5%;
  position: absolute;
  z-index: 99;
}

.part-inputpart-dropDownList-option {
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}

.part-inputpart-dropDownList-option-selected {
  color: #ff5d3b;
}

.part-inputpart-row-graytext {
  /*width: 100%;*/
  color: #d8d8d8;
  flex: auto;
}

.part-inputpart-row-redtext {
  color: #ff5d3b;
}

.part-inputpart-row-normaltext {
  color: black;
}

.part-inputpart-man {
  margin-left: 150px;
  margin-right: 18px;
}

.part-inputpart-woman {
  margin-left: 18px;
}

.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-no {
  padding: 3px;
  margin: 0 10px;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

/*弹窗样式*/
.van-popup--bottom {
  /*z-index: 2004;*/
  background-color: #f8f8f8;
  overflow: hidden;
  /*padding-bottom: 60px;*/
}
.cancelOrderPopupImg {
  float: left;
  margin: 20px 5px 0 15px;
  width: 15px;
  height: 15px;
}
.cancelOrderPopupTitle {
  margin-top: 18px;
  font-size: 15px;
  font-weight: bold;
}
.cancelOrderPopupText {
  margin-left: 35px;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: bold;
}
.saveButton_able {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll
    0% 0%;
  color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable {
  background: linear-gradient(to right, #999999, #cccccc 100%) repeat scroll 0%
    0%;
  color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.22rem;
  text-align: center;
}
</style>
